import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import middlewarePipeline from "@/router/middlewarePipeline";
import { Route } from "vue-router";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Loans",
    component: () =>
      import(/* webpackChunkName: "loans" */ "../views/Loans.vue"),
    meta: {
      name: "Loans",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/payments/",
    name: "Payments",
    component: () =>
      import(/* webpackChunkName: "paymentsAll" */ "../views/Payments.vue"),
    meta: {
      name: "Payments",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/payments/:loanId",
    name: "Payments by loan",
    props: (route) => {
      const loanId = Number.parseInt(route.params.loanId, 10);
      if (Number.isNaN(loanId)) {
        return null;
      }
      return { loanId };
    },
    component: () =>
      import(/* webpackChunkName: "payments" */ "../views/Payments.vue"),
    meta: {
      name: "Payments by loan",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/customer-service",
    name: "Customer Service",
    component: () =>
      import(
        /* webpackChunkName: "customerService" */ "../views/CustomerService.vue"
      ),
    meta: {
      name: "Customer Service",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      name: "Login",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "all",
    },
  },
  {
    path: "/two-factor",
    name: "Two Factor",
    component: () =>
      import(/* webpackChunkName: "forgotPassword" */ "../views/TwoFactor.vue"),
    meta: {
      name: "Two Factor",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "all",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "userProfile" */ "../views/UserProfile.vue"),
    meta: {
      name: "Profile",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/ForgotPassword.vue"
      ),
    meta: {
      name: "Forgot Password",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "all",
    },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/ResetPassword.vue"
      ),
    meta: {
      name: "Reset Password",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "all",
    },
  },
  // {
  //   path: "/loan-application",
  //   name: "Loan Application",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "loanApplication" */ "../views/LoanApplication.vue"
  //     ),
  //   meta: {
  //     name: "Loan Application",
  //     allowedToView: ["admin", "broker", "user"],
  //     authRequired: true,
  //     group: "user",
  //   },
  // },
  {
    path: "/loans/:id",
    name: "Loan Details",
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    component: () =>
      import(
        /* webpackChunkName: "loanDetails" */ "../views/loans/LoanDetails.vue"
      ),
    meta: {
      name: "Loan Details",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/profile/edit",
    name: "Profile Edit",
    component: () =>
      import(
        /* webpackChunkName: "profileEdit" */ "../views/user/ProfileEdit.vue"
      ),
    meta: {
      name: "Profile Edit",
      allowedToView: ["admin", "broker", "user"],
      authRequired: true,
      group: "user",
    },
  },
  {
    path: "/admin/dashboard",
    name: "Admin Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/Dashboard.vue"
      ),
    meta: {
      name: "Admin Dashboard",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
    // beforeEnter: (to, from, next) => {
    //   AuthService.getAuthUser()
    //     .then((r) => {
    //       if (
    //         process.env.VUE_APP_SHOW_ADMIN_PANEL === "false" &&
    //         r.data.role_id !== 1
    //       ) {
    //         next({ name: "Loans" });
    //       }
    //       next();
    //     })
    //     .catch();
    // },
  },
  {
    path: "/admin/user-list",
    name: "User List",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/UserListAdmin.vue"
      ),
    meta: {
      name: "User List",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
  },

  /**
   * Admin Route List
   */
  {
    path: "/admin/broker-list",
    name: "Broker List",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/BrokerListAdmin.vue"
      ),
    meta: {
      name: "Broker List",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
  },
  {
    path: "/admin/admin-list",
    name: "Admin List",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/AdminListAdmin.vue"
      ),
    meta: {
      name: "Admin List",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
  },
  {
    path: "/admin/report/:id",
    name: "Admin Report",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/Report/SingleUserReport.vue"
      ),
    meta: {
      name: "Admin Report",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
  },
  {
    path: "/admin/settings",
    name: "Admin Settings",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/admin/SettingsPage.vue"
      ),
    meta: {
      name: "Admin Settings",
      allowedToView: ["admin"],
      authRequired: true,
      group: "admin",
    },
  },
  /**
   * END Admin Route List
   */

  {
    path: "/ob-link-success",
    name: "openBankingLinkSuccess",
    component: () =>
      import(
        /* webpackChunkName: "openBanking" */ "../views/OpenBankingSuccess.vue"
      ),
    meta: {
      name: "Open Banking link success",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "user",
    },
  },
  {
    path: "/ob-link-failed",
    name: "openBankingLinkFailed",
    component: () =>
      import(
        /* webpackChunkName: "openBanking" */ "../views/OpenBankingFailed.vue"
      ),
    meta: {
      name: "Open Banking link failed",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "user",
    },
  },

  {
    path: "*",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
    meta: {
      name: "Not Found",
      allowedToView: ["admin", "broker", "user"],
      authRequired: false,
      group: "all",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next) => {
  if (to.meta === undefined) return;
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

let triedBeforeToLoadUser = false;

router.beforeEach(async (to, from, next) => {
  const allowedTo = to.meta?.allowedToView;
  const group = to.meta?.group;
  let userAuth = store.state.auth.user;
  let hasSwitchedToUser = store.getters["auth/guest"];
  let role = "";

  // If we have a special query string parameter, we may be coming from an open banking uri. Let's parse it and sent our
  // user to the right 'nice' page for it
  const query = to.query;
  if (query && query.consent) {
    const consent = query.consent;
    if (consent === "true") {
      next({ name: "openBankingLinkSuccess" });
    } else if (consent === "false") {
      next({ name: "openBankingLinkFailed" });
    }
    return;
  }

  // If we don't have a logged in user, and don't tried to find it out, let's try to find it out
  if (!userAuth && !triedBeforeToLoadUser) {
    triedBeforeToLoadUser = true;
    await store.dispatch("auth/getAuthUser");
    userAuth = store.state.auth.user;
    hasSwitchedToUser = store.getters["auth/guest"];

    // If we are logged in, we can go to the right page for this user
    if (userAuth) {
      role = ["admin", "broker", "user"][userAuth.role_id - 1];

      if (!hasSwitchedToUser) {
        if (role === "admin" && (group === "user" || group === "broker")) {
          next("/admin/dashboard");
        } else {
          next();
        }
        return;
      }
    }
  }

  if (userAuth) {
    // Now we know we are logged in

    if (hasSwitchedToUser) {
      role = "user";
    }

    if (!allowedTo.includes(role)) {
      if (role === "user") {
        next({ name: "Loans" });
      } else if (role === "broker") {
        next("/broker/");
      } else if (role === "admin") {
        next({ name: "Admin Dashboard" });
      }
    }

    // Now we know we are logged in and are allowed to view the page Do we need to check anything else now?

    if (group === "admin" && process.env.VUE_APP_SHOW_ADMIN_PANEL === "false") {
      next("/");
    }

    // At this point we are allowed to go to any page we want, so let's go there
    next();
  } else if (!to.meta?.authRequired) {
    next();
  } else {
    next({ name: "Login" });
  }
});

export default router;
